








































































































































































































































































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import lodash from "lodash";
import table from "@/utils/table";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableRowHeader from "vuetable-2/src/components/VuetableRowHeader.vue";
import VuetableFieldCheckbox from "vuetable-2/src/components/VuetableFieldCheckbox.vue";

import DetailRow from "@/components/Shared/Table/DetailRow.vue";
import RowFilter from "@/components/Shared/Table/RowFilter.vue";

import DetailField from "@/components/Shared/Table/DetailField.vue";
import StatusField from "@/components/Shared/Table/StatusField.vue";
import ActionBopAccueilField from "@/components/Shared/Table/ActionBopAccueilField.vue";
import ActionBopField from "@/components/Shared/Table/ActionBopField.vue";
import CategoryField from "@/components/Shared/Table/CategoryField.vue";
import EditField from "@/components/Shared/Table/EditField.vue";
import DropdownField from "@/components/Shared/Table/DropdownField.vue";
import CoeffOptiondropdownField from "@/components/Shared/Table/CoeffOptionDropdownField.vue";
import SwitchField from "@/components/Shared/Table/SwitchField.vue";
import SitesTypeTravauxField from "@/components/Shared/Table/SitesTypeTravauxField.vue";
import OperationTypeField from "@/components/Shared/Table/OperationTypeField.vue";
import OperationFamiliesField from "@/components/Shared/Table/OperationFamiliesField.vue";
import ComplementsCoutsSuppField from "@/components/Shared/Table/ComplementsCoutsSuppField.vue";
import CoefficientSitesField from "@/components/Shared/Table/CoefficientSitesField.vue";
import {BIconFiles, BIconTrashFill} from "bootstrap-vue";
import api from "@/utils/api";
import {Guid} from "guid-typescript";
import TableApiHelper from "@/utils/TableApiHelper";
import {debounce} from "@/utils/helpers";

import ImportUsersModal from "@/components/Users/ImportUsersModal.vue";

Vue.component("my-detail-row", DetailRow);
Vue.component("vuetable-field-checkbox", VuetableFieldCheckbox);

type SortOrder = {
  field: string;
  direction: string;
  sortField: string;
};

@Component({
  components: {
    VuetablePagination,
    VuetableRowHeader,
    RowFilter,
    DetailField,
    StatusField,
    ActionBopAccueilField,
    ActionBopField,
    CategoryField,
    EditField,
    SwitchField,
    DropdownField,
    CoeffOptiondropdownField,
    SitesTypeTravauxField,
    OperationTypeField,
    OperationFamiliesField,
    BIconTrashFill,
    BIconFiles,
    ComplementsCoutsSuppField,
    CoefficientSitesField,
    // UnsavedModal
    Modal: () => import("@/components/Shared/Modal.vue"),
    ImportUsersModal: ImportUsersModal,
  },
})
export default class Table extends Vue {
  @Prop({required: true}) public columns!: Array<Object>;
  @Prop() public title!: String;
  @Prop() public addLabel!: String;
  @Prop({default: () => "plus"}) public addIcon!: String;
  @Prop() public saveLabel!: String;
  @Prop() public importLabel!: String;
  @Prop() public exportLabel!: String;
  @Prop() public bulkDeleteLabel!: String;
  @Prop() public bulkDuplicateLabel!: String;
  @Prop() public searchLabel!: String;
  @Prop() public modifiedData!: Array<Object>;
  @Prop({required: false}) public url!: string;
  @Prop({required: true}) public trackBy!: string;
  @Prop({default: ()=> ""}) public bulkTrackBy!: string;
  @Prop() public searchBar!: Boolean;
  @Prop() public checkedRows!: Array<Object>;
  @Prop() readonly hideAdd!: Boolean;
  @Prop() readonly hideSave!: Boolean;
  @Prop() readonly notEditable!: Boolean;
  @Prop() readonly notAllowedExcel!: Boolean;
  @Prop() readonly saveAlwaysActive!: Boolean;
  @Prop() readonly exportUrl!: string;
  @Prop({default: () => true}) readonly isButtonFooterVisible!: Boolean;
  @Prop({default: () => false}) readonly isPartialLoad!: Boolean;
  @Prop({default: () => 0}) readonly pageSize!: number;
  @Prop({default: () => []}) readonly columnsToFilter!: Array<string>;
  @Prop() public isArkemaUsers!: boolean;

  public myDetailRow: object = DetailRow;
  public updatedRows: Array<Object> = [];
  public initialRows: Array<Object> = [];
  @Prop() public isRowClickable!: Boolean;
  @Prop({default: () => undefined}) private readonly initialData!: Array<any>;
  private selectedRows: Array<Object> = [];
  private bulkActionsSelectedRows: Array<string> = [];
  private sortOrder: Array<Object> = [];
  private perPage: number = 20;
  private hasBeenModified: Boolean = false;
  private pagination: any = undefined;
  private input: string = "";
  private searchInput: string = "";
  private loading: Boolean = false;
  private selectedRow: Array<String> = [];
  private vue: any = this.$parent.$parent.$parent;
  private tableApiHelper: TableApiHelper = new TableApiHelper(null,null);
  private bulkCheckedRowsHistory:Map<string,any> = new Map<string, any>();

  private debounceChecker:number|null = null;
  private get isRowClickTemporaryDisabled(): boolean {
    return this.$store.state.isRowClickTemporaryDisabled;
  }

  private set isRowClickTemporaryDisabled(value: boolean) {
    this.$store.commit("changeIsRowClickTemporaryDisabled", value);
  }
  private get vueTable():any{
    return this.$refs.vuetable;
  }
  private get displayedProperties() {
    return this.columns.map((column) => {
      let prop = (<any>column).display || (<any>column).property;
      prop = prop.toString().toUpperCase();
      return prop.split(".")[0] || prop;
    });
  }

  get localIsArkemaUsers(): boolean {
    return this.isArkemaUsers;
  }

  public save(): void {
    if((!this.$store.state.hasBeenModified || this.hideSave) &&
        !this.saveAlwaysActive)return;
    // this.selectedRow = [];
    this.$emit("save-data");
    // this.$store.commit("changeHasBeenModified", false);
    // this.refresh();
  }

  public add(): void {
    if(this.hideAdd)return;
    const previousRows = lodash
        .cloneDeep(this.updatedRows)
        .map((n) => n[this.trackBy]);
    this.$store.commit("changeHasBeenModified", true);
    this.$emit("add-data");

    if (this.updatedRows.length > previousRows.length) {
      const res = this.updatedRows
          .map((n) => n[this.trackBy])
          .filter((n) => !previousRows.includes(n));
      this.selectedRow.push(res[0]);
    }
  }

  public refresh(soft: boolean = false): void {
    if (soft) {
      (<any>this.$refs.vuetable).refresh();
      return;
    }
    this.onLoading();
    this.$store.commit("changeIsFirstCheckBoxTableLoad", true);
    this.$store.commit("changeHasBeenModified", false);
    this.bulkActionsSelectedRows = [];
    (<any>this.$refs.vuetable).refresh();
    this.onLoaded();
  }


  @Watch("url")
  onUrlChange(newValue: any, oldvalue: any) {
    this.refreshAPI();
  }
  refreshData() {
    this.refreshAPI();
  }

  @Watch("initialData")
  onInitialDataChange() {
    this.loadFromInitData();
  }

  @Watch("input")
  onInputChange(newValue: string, oldvalue: string) {
    const filter = newValue.toUpperCase();
    this.updatedRows = [];
    this.initialRows.forEach((row) => {
      for (let value of Object.values(row)) {
        if (
            value &&
            typeof value == "string" &&
            value.toString().toUpperCase().indexOf(filter) > -1
        ) {
          this.updatedRows.push(row);
          break;
        }
      }
    });
    this.refresh();
  }
  public isBulkAllSelected(selectedRowsHash:Set<string> = new Set<string>()){

    this.bulkActionsSelectedRows.forEach((trackId:string) => selectedRowsHash.add(trackId));
    let counter = 0;
    this.vueTable.tableData.forEach(rowData => {
      const trackId:string = this.tableApiHelper?.findByPath(rowData,this.bulkTrackBy);
      if(selectedRowsHash.has(trackId)){
        counter++;
      }
    })
    return this.vueTable.tableData.length < this.perPage ? counter === this.vueTable.tableData.length : counter === this.perPage;
  }
  public bulkSelectAll(){
    const selectedRowsHash:Set<string> = new Set<string>();

    if(this.isBulkAllSelected(selectedRowsHash)){
      this.vueTable.tableData.forEach(rowData => {
        const trackId:string = this.tableApiHelper?.findByPath(rowData,this.bulkTrackBy);
        selectedRowsHash.delete(trackId);
      });
      this.bulkActionsSelectedRows = Array.from(selectedRowsHash.values());
      return;
    }
    this.vueTable.tableData.forEach(rowData => {
      const trackId:string = this.tableApiHelper?.findByPath(rowData,this.bulkTrackBy);
      if(!selectedRowsHash.has(trackId)) {
        this.bulkActionsSelectedRows.push(trackId);
      }
    });
  }

  public onPaginationData(paginationData: any): void {
    (<any>this.$refs.pagination).setPaginationData(paginationData);
  }

  /**
   * add new row to the table
   * @param newRow
   */
  public prependRow(newRow: any) {
    this.updatedRows.unshift(newRow);
  }

  public deleteRow(rowIndex) {
    this.updatedRows.splice(rowIndex, 1);
  }

  /**
   * modify
   * Triggered when a cell has been modified
   */
  public modify(props: any) {
    const path = this.vueTable.trackBy;
    this.$store.commit("changeHasBeenModified", true);
    const propToSearch = table.findByPath(props, path);

    let index = this.modifiedData.findIndex(function (item, index) {
      return propToSearch === table.findByPath(item, path);
    });
    if (index === -1) {
      this.modifiedData.push(props);
    } else {
      this.modifiedData.splice(index, 1, props);
    }
  }

  @Watch("updatedRows")
  onPropertyChanged(value: Array<Object>, oldValue: Array<Object>) {
    this.refresh();
  }

  @Watch("selectedRows")
  onselectedRowsChanged(value: Array<Object>, oldValue: Array<Object>) {
    if (this.$store.state.isFirstCheckBoxTableLoad) {
      this.$store.commit("changeIsFirstCheckBoxTableLoad", false);
    } else {
      this.$store.commit("changeHasBeenModified", true);
    }
    this.$emit("rowsSelectionChange", value);
  }

  @Watch("checkedRows")
  oncheckedRowsChange(value: Array<Object>, oldValue: Array<Object>) {
    this.selectedRows = value;
  }

  public created() {
    this.tableApiHelper = new TableApiHelper(this.$store, this.$api);
    this.bulkTrackBy??= this.trackBy;
  }

  public mounted() {
    if (this.url) {
      this.refreshAPI();
    } else if (this.initialData) {
      this.loadFromInitData()
    }
  }

  private rowClick(data: any) {
    if (this.isRowClickable) {
      if (!this.isRowClickTemporaryDisabled) {
        this.$emit("row-clicked", data);
      } else {
        this.isRowClickTemporaryDisabled = false;
      }
    }
  }

  private hideKeyboard() {
    (<HTMLInputElement>document.activeElement).blur();
  }

  private onLoading() {
    this.loading = true;
  }

  private onLoaded() {
    this.loading = false;
    this.$emit("on-loaded");
  }
  private  updateBulkCheckedRowsHistory(){
    this.vueTable.tableData.forEach(row => {
      const key:string = table.findByPath(row,this.bulkTrackBy);
      this.bulkCheckedRowsHistory.set(key,row);
    });
  }
  private handleBulkDuplicate(){
    if(!this.bulkActionsSelectedRows.length)return;
    this.updateBulkCheckedRowsHistory();
    const allCheckedTableData = Array.from(this.bulkCheckedRowsHistory.values());
    this.$emit('handleBulkDuplicate',this.tableApiHelper.getValuesFromTrackId(allCheckedTableData,this.bulkActionsSelectedRows,this.bulkTrackBy))
  }
  private handleBulkDelete(){
    if(!this.bulkActionsSelectedRows.length)return;
    this.updateBulkCheckedRowsHistory();
    const allCheckedTableData = Array.from(this.bulkCheckedRowsHistory.values());
    this.$emit('handleBulkDelete',this.tableApiHelper.getValuesFromTrackId(allCheckedTableData,this.bulkActionsSelectedRows,this.bulkTrackBy))
  }

  private download(props: any) {
    this.$emit("download-data", props);
  }

  private onChangePage(page: any): void {
    this.updateBulkCheckedRowsHistory();
    (<any>this.$refs.vuetable).changePage(page);
    this.$emit('pagination-change-page');
  }

  private getRawData(props: any): any {
    const obj = props.rowData;
    const path = props.rowField.property;
    return table.findByPath(obj, path);
  }

  private async refreshAPI() {
    this.onLoading();
    this.$store.commit("changeIsFirstCheckBoxTableLoad", true);
    let url: string = this.url;

    if (this.isPartialLoad && this.pageSize > 0) {
      const inedxOfQuestionMarque = url.indexOf("?");
      if (inedxOfQuestionMarque === -1) url += "?";
      const isFirstParam =
          inedxOfQuestionMarque === -1 ||
          inedxOfQuestionMarque === url.length - 1;
      let pageIndex = 0;
      let pageSize = this.pageSize;
      let partialLoadUrl = `${url}${
          isFirstParam ? "" : "&"
      }pageIndex=${pageIndex}&pageSize=${pageSize}`;
      let response = await api.get(partialLoadUrl);
      (<any>this.$refs.vuetable).resetData();
      this.updatedRows = response.data;
      this.initialRows = response.data;
      (<any>this.$refs.vuetable).refresh();
      (<any>this.$refs.pagination).resetData();
      setTimeout(async () => {
        while (response.data.length === pageSize) {
          pageIndex++;
          partialLoadUrl = `${url}${
              isFirstParam ? "" : "&"
          }pageIndex=${pageIndex}&pageSize=${pageSize}`;
          response = await api.get(partialLoadUrl);
          const newData = this.updatedRows.concat(response.data);
          this.updatedRows = newData;
          this.initialRows = newData;
        }

        (<any>this.$refs.vuetable).refresh();
        (<any>this.$refs.pagination).resetData();
      }, 200);
    } else {
      const response = await api.get(url);
      if (response.data) {
        (<any>this.$refs.vuetable).resetData();
        this.updatedRows = response.data;
        this.initialRows = response.data;
        (<any>this.$refs.vuetable).refresh();
        (<any>this.$refs.pagination).resetData();
      }
    }
    this.onLoaded();
  }

  private async loadFromInitData() {
    this.onLoading();
    await this.$nextTick();
    const initialData = lodash.cloneDeep(this.initialData);
    (<any>this.$refs.vuetable).resetData();
    this.updatedRows = initialData;
    this.initialRows = initialData;
    (<any>this.$refs.vuetable).refresh();
    (<any>this.$refs.pagination).resetData();
    this.onLoaded();
  }
  public isNewRow(dataItem){
    return this.selectedRow.includes(dataItem[this.trackBy]);
  }
  private onRowClass(dataItem, index) {
    return this.selectedRow.includes(dataItem[this.trackBy])
        ? "selected-row"
        : "";
  }

  private async duplicate(params: any) {
    const rowData = params.rowData;
    const rowIndex = params.rowIndex + 1;

    const restOfArray = this.updatedRows.splice(rowIndex);
    let newData = lodash.cloneDeep(rowData);
    await this.$nextTick();

    table.setByPath(newData, this.trackBy, Guid.create().toString());
    this.updatedRows.push(newData);
    this.updatedRows = this.updatedRows.concat(restOfArray);

    this.selectedRow.push(newData[this.trackBy]);
    this.modify(newData);
    this.$emit("duplicate-data", newData);
  }

  //Recursive method to search a string in every nested property of an object

  private remove(props: any) {
    if (props.rowField.disableRemoveButton) return;
    this.$emit("remove-data", {
      rowData: props.rowData,
      rowIndex: props.rowIndex,
    });
  }

  private showModal(props: any) {
    this.$emit("open-anotation-modal", props);
  }

  /**
   * Sort rows based on sortOrder
   * Update the pagination
   *
   * @param {array} sortOrder sortOrder that has been changed
   * @param {any} pagination global sorting order
   * @return {object} sorted rows, pagination
   */
  private dataManager(sortOrder: Array<SortOrder>, pagination: any) {
    if (this.updatedRows.length < 1) {
      return {
        pagination: (<any>this.$refs.vuetable).makePagination(0, this.perPage),
        data: [],
      };
    }

    let local = this.updatedRows;

    if (sortOrder.length > 0) {
      // special rule for an array inside the object to sort
      if (sortOrder[0].sortField.includes("sites") || sortOrder[0].sortField.includes("bopModels")) {
        local = lodash.orderBy(
            local,
            (o) => lodash.get(o, sortOrder[0].sortField).map(el => el?.translation.name).join("").toLowerCase(),
            this.sortOrder.map((order) => (<SortOrder>order).direction)
        );
      } else {
        local = lodash.orderBy(
            local,
            this.sortOrder.map((order) => (<SortOrder>order).sortField),
            this.sortOrder.map((order) => (<SortOrder>order).direction)
        );
      }
    }

    pagination = (<any>this.$refs.vuetable).makePagination(
        local.length,
        this.perPage
    );

    let from = pagination.from - 1;
    let to = from + this.perPage;

    return {
      pagination: pagination,
      data: lodash.slice(local, from, to),
    };
  }

  /**
   * Update the rows based on a search value
   */

  private search(): void {
    this.debounceChecker = debounce(() => {

      //if the columns to filter were specified we override the default behaviour
      if (this.columnsToFilter.length) {
        const columnsToExclude: string[] = Object.keys(this.initialRows[0]).filter(columnName => !this.columnsToFilter.includes(columnName));
        this.initFilter(columnsToExclude);
        return;
      }
      // Exclude all non displayed properties
      let objectProperties: Array<string> = [];
      if (this.initialRows.length) {
        objectProperties = Object.keys(this.initialRows[0]).map((prop) =>
            prop.toString().toUpperCase()
        );
      }

      const excludedProperties: Array<string> = objectProperties.filter(
          (prop) => !this.displayedProperties.includes(prop)
      );

      this.initFilter(excludedProperties);
    }, this.debounceChecker);

  }

  private initFilter(excludedProperties: Array<string>) {
    const filter = this.searchInput.toUpperCase();
    this.updatedRows = [];
    this.initialRows.forEach((row) => {
      if (this.searchRecursive(row, filter, excludedProperties)) {
        this.updatedRows.push(row);
      }
    });
    this.refresh();
  }

  private format(date) {
    const datetoConvert = new Date(date);
    return new Date(
      // this.rawData
      datetoConvert.getTime() - (datetoConvert.getTimezoneOffset() * 60000)
    )?.toLocaleTimeString([], {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  /**
   * Update rows based on filter if filters are allowed
   * TODO :
   * filter function
   */

  //Excluded properties will be ignored
  private searchRecursive(
      object: any,
      searchText: string,
      excludedProperties: Array<string> = ["ID"]
  ): boolean {
    excludedProperties = excludedProperties.map(p => p.toUpperCase());
    for (const [key, value] of Object.entries(object)) {
      if (!value || excludedProperties.includes(key.toUpperCase())) {
        continue;
      }

      // dates
      if(key.toUpperCase().includes('DATE')) {
        if(this.format(value).toString().toUpperCase().indexOf(searchText) > -1) {
          return true;
        }
      }
      //String , Number case : compare with search text
      else if (
        (typeof value == "string" || typeof value == "number") && value.toString().toUpperCase().indexOf(searchText) > -1
      ) {
        return true;
      }
      //Array case : test every element
      else if (Array.isArray(value)) {
        for (let element of value) {
          if (this.searchRecursive(element, searchText)) {
            return true;
          }
        }
      }
          //Need to be tested last because everything extends object
      //Nested object, call recursively
      else if (typeof value == "object") {
        if (this.searchRecursive(value, searchText)) {
          return true;
        }
      }
    }
    //Nothing found, return false
    return false;
  }

  private onChange(data: any) {
  }
  public uploadUsers() {
    (<ImportUsersModal>this.$refs.importModal).openImportModal();
  }
}
